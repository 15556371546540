import Component from '../../../assets/scripts/modules/component'
import insertScript from '../../../assets/scripts/utilities/insert-script-async'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

export default class MicrioEmbedComponent extends Component {
  init() {
    this.id = this.element.getAttribute('data-id') || ''
    this.container = this.element.querySelector('.micrio-embed__container')
    this.language = document.querySelector('html').getAttribute('lang') || 'en'

    if (!this.id) {
      return
    }

    this.buttonCookies = this.element.querySelector('.button--cookies')
    this.notice = this.element.querySelector('.micrio-embed__cookies-notice')

    if (this.buttonCookies) {
      this.buttonCookies.addEventListener('click', event => {
        event.preventDefault()
        window.dispatchEvent(new CustomEvent('show-cookie-details'))
      })
    }

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  checkCookie() {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.showCookieNotice()
    } else {
      this.hideCookieNotice()
    }
  }

  showCookieNotice() {
    if (this.notice) {
      this.notice.removeAttribute('hidden')
    }

    this.element.classList.add('micrio-embed--cookies-not-accepted')
  }

  hideCookieNotice() {
    if (this.notice) {
      this.notice.setAttribute('hidden', 'hidden')
    }

    this.element.classList.remove('micrio-embed--cookies-not-accepted')
    this.initExternalScript()
  }

  async initExternalScript() {
    if (!this.container || this.micrio) {
      return
    }

    this.micrio = document.createElement('micr-io')
    this.micrio.setAttribute('id', this.id)
    this.micrio.setAttribute('scroll-zoom', 'false')
    this.micrio.setAttribute('skipmeta', 'false')
    this.micrio.setAttribute('logo', 'false')
    this.micrio.setAttribute('toolbar', 'true')
    this.micrio.setAttribute('minimap', 'false')
    this.micrio.setAttribute('show-info', 'false')
    this.micrio.setAttribute('controls', 'true')
    this.micrio.classList.add('micrio-embed__micrio')
    this.micrio.classList.add('defaultSettings')

    this.micrio.addEventListener('show', event => {
      if (!event.detail?.$current?.$data?.markers) {
        return
      }

      let markers = [...event.detail.$current.$data.markers]
      markers = markers.filter(marker => marker.data?.showTitle)
      markers.forEach(marker => {
        const element = document.querySelector(`#${marker.id} label`)
        if (!element) {
          return
        }

        element.style.opacity = 1
      })
    })

    this.container.appendChild(this.micrio)

    if (!document.getElementById('micrio-script')) {
      await insertScript('micrio-script', 'https://b.micr.io/micrio-4.3.min.js')
    }
  }
}

window.addEventListener('init-delayed-load', () =>
  [...document.querySelectorAll('.micrio-embed')].forEach(element => {
    element.instance = element.instance || new MicrioEmbedComponent(element)
  })
)
